<template>
  <div class="view view--user">

    <div class="view-header" v-if="pageTitle">
      <div class="view-header__footer">
        <h1 class="page-title" v-html="pageTitle"></h1>
      </div>
    </div>

    <div class="view-body">
      <Section>
        <Container>
          <h2>Informations</h2>
          <KeyValue label="Version" :value="currentVersion" />
          <KeyValue label="Version du navigateur" :value="userAgent" />
          <p><Btn text="Recharger l'application" color="primary" @click="refreshApp()" /></p>
          <p><Btn text="Nettoyer l'application" color="accent" @click="cleanDataModal = true" /></p>
        </Container>
      </Section>
    </div>

  </div>

  <Modal
    title="Nettoyer l'application"
    :active="cleanDataModal"
    @modal-close="cleanDataModal = false"
  >
    <template v-slot:modal-body>
      <p><strong>Voulez supprimer toutes les données de l'application ?</strong></p>
      <p><strong class="color-error">Les données des notations non synchronisées seront perdues.</strong></p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="cleanDataModal = false" />
      <Btn
        text="Tout supprimer"
        @click="cleanAll(data)"
        icon="trash-alt"
          color="accent"
      />
    </template>
  </Modal>

</template>

<script>

import KeyValue from '@/components/form/KeyValue.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Btn from '@/components/base/Btn.vue'
import Modal from '@/components/layout/Modal.vue'

export default {
  name: 'AboutView',

  components: {
    KeyValue,
    Section,
    Container,
    Btn,
    Modal,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      resolution: '',
      userAgent: navigator.userAgent,
      currentVersion: null,
      cleanDataModal: false,
    }
  },

  mounted() {
    console.log('navigator', navigator)
    this.currentVersion = process.env.VERSION
  },

  methods: {
    cleanAll() {
      localStorage.clear()
      window.location.reload(true)
    },
    refreshApp() {
      this.emitter.emit('open-loader')
      this.$store.dispatch('app/setReferentials').then(() => {
        this.$store.dispatch('app/setData').then(() => {
          this.emitter.emit('close-loader')
          window.location.reload(true)
        }, () => {
          this.emitter.emit('close-loader')
        })
      })
    },
  },
}
</script>
